import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  auth_CheckSignIn,
  auth_SignOut,
  firebase_UpdateDocument,
} from "./firebase";
import "./STYLES/Dashboard.css";
import { CirclePicker } from "react-color";
import { BiSolidHappyBeaming } from "react-icons/bi";
import { PiSignOutBold } from "react-icons/pi";
import { stripeSubscriptionLink } from "./constants";
import { stripe_GetPaymentUrl } from "./stripe";

function Typewriter({ text, classes }) {
  const [displayText, setDisplayText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (text !== undefined && currentIndex < text.length) {
      const timeout = setTimeout(() => {
        let nextChar = text[currentIndex];

        // Handle newline characters
        if (nextChar === "\n") {
          setDisplayText((prevText) => prevText + "<br />");
        } else {
          setDisplayText((prevText) => prevText + nextChar);
        }

        setCurrentIndex(currentIndex + 1);
      }, 100); // Typing speed in milliseconds (adjust as needed)

      return () => clearTimeout(timeout);
    }
  }, [text, currentIndex]);

  return (
    <span
      className={classes}
      dangerouslySetInnerHTML={{ __html: displayText }}
    />
  );
}

export default function Dashboard() {
  const navigate = useNavigate();
  const [me, setMe] = useState({});
  const [showAPI, setShowAPI] = useState(false);
  const [iconColor, setIconColor] = useState("#000000");
  const [showSignOut, setShowSignOut] = useState(false);

  function onSave() {
    const businessName = document.querySelector("#tbBusinessName").value;
    const businessEmail = document.querySelector("#tbBusinessEmail").value;
    const squareAPI = document.querySelector("#tbSquareAPI").value;
    const instructions = document.querySelector("#taInstructions").value;

    firebase_UpdateDocument(
      "Accounts",
      me.id,
      {
        BusinessName: businessName,
        Email: businessEmail,
        SquareAPI: squareAPI,
        Instructions: instructions,
        IconColor: iconColor,
      },
      (success) => {
        if (success) {
          alert(
            "Your changes were saved successfully. Refresh to view changes in Coco preview."
          );
        } else {
        }
      }
    );
  }
  function onSignOut() {
    auth_SignOut((success) => {
      if (success) {
        navigate("/login");
      }
    });
  }
  function onPay(amountInCents) {
    stripe_GetPaymentUrl(amountInCents, (url) => {
      console.log(url)
    })
  }

  useEffect(() => {
    auth_CheckSignIn(
      (isSignedIn) => {
        if (!isSignedIn) {
          navigate("/login");
        }
      },
      (thisUser) => {
        setMe(thisUser);
        // Ensure elements are found before setting values
        const businessNameInput = document.querySelector("#tbBusinessName");
        const businessEmailInput = document.querySelector("#tbBusinessEmail");
        const squareAPIInput = document.querySelector("#tbSquareAPI");
        const instructionsTextarea = document.querySelector("#taInstructions");

        if (businessNameInput) {
          businessNameInput.value = thisUser.BusinessName || "";
        }

        if (businessEmailInput) {
          businessEmailInput.value = thisUser.Email || "";
        }

        if (squareAPIInput) {
          squareAPIInput.value = thisUser.SquareAPI || "";
        }

        if (instructionsTextarea) {
          instructionsTextarea.value = (thisUser.Instructions || "").replaceAll(
            "jjj",
            "\n"
          );
        }
        setIconColor(
          thisUser.IconColor !== undefined ? thisUser.IconColor : "#117DFA"
        );
      }
    );
  }, [me.BusinessName]);

  return (
    <div className="main jakarta">
      {/*  */}
      {showSignOut && (
        <div className="dash-signout">
          <div></div>
          <div>
            <div></div>
            <div>
              {/* CONTENT HERE */}
              <h3 className="text-center">Sign Out</h3>
              <p className="text-center">Are you sure you want to sign out?</p>
              <div className="signout-btns">
                <button
                  style={{ color: "#ffffff", backgroundColor: "#343F52" }}
                  onClick={() => {
                    setShowSignOut(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  style={{ color: "#ffffff", backgroundColor: "red" }}
                  onClick={onSignOut}
                >
                  Sign Out
                </button>
              </div>
            </div>
            <div></div>
          </div>
          <div></div>
        </div>
      )}
      <div className="dash-main">
        {me.id !== undefined && (
          <div className="dash-left">
            <div className="separate_h">
              <Typewriter
                classes={"dash-title"}
                text={`Hello, ${me.BusinessName}`}
              />
              <PiSignOutBold
                color="red"
                size={25}
                onClick={() => {
                  setShowSignOut(true);
                }}
                className="pointer"
              />
            </div>
            <p className="no dash-caption">
              Review your details, correct or change any information.
            </p>
            <br />
            <div className="dash-fields">
              <div className="dash-block">
                <p className="no dash-label">Status</p>
                <div className="dash-status no">
                  <p className={`${me.Active ? "green" : "red"} no`}>{me.Active ? "Active" : "Not Active"}</p>
                  {!me.Active && me.Balance === 0 && (
                    <p onClick={() => {
                      window.open(stripeSubscriptionLink)
                    }} className="no active-btn pointer">Activate Coco</p>
                  )}
                </div>
              </div>
              <div className="dash-block">
                <p className="no dash-label">Balance</p>
                <div className="dash-status no">
                  <p className={`no`}>${me.Balance.toFixed(2)}</p>
                  {me.Balance > 0 && (
                    <p onClick={() => {
                      onPay(me.Balance * 100)
                    }} className="no active-btn pointer">Make Payment</p>
                  )}
                </div>
              </div>
              <div className="dash-block">
                <p className="no dash-label">Business Name</p>
                <input
                  type="text"
                  className="dash-input"
                  id="tbBusinessName"
                  placeholder="Business Name"
                />
              </div>
              <div className="dash-block">
                <p className="no dash-label">Business Email</p>
                <input
                  type="text"
                  className="dash-input"
                  id="tbBusinessEmail"
                  placeholder="Business Email"
                />
                <p className="no dash-detail">
                  You will be using the original email to sign in. However, this
                  email will be used for any submissions through the chat.
                </p>
              </div>
              <div className="dash-block">
                <p className="no dash-label">Square API Key</p>
                <div className="side-by">
                  <input
                    type={showAPI ? "text" : "password"}
                    className="dash-input"
                    id="tbSquareAPI"
                    placeholder="Square API Key"
                  />
                  <button
                    className="dash-show-btn"
                    onClick={() => {
                      setShowAPI((prev) => !prev);
                    }}
                  >
                    {showAPI ? "Hide" : "Show"}
                  </button>
                </div>
              </div>
              <div className="dash-block">
                <div className="side-by">
                  <p className="no dash-label" style={{ alignItems: "center" }}>
                    Icon Color
                  </p>
                  <BiSolidHappyBeaming color={iconColor} size={22} />
                </div>
                <CirclePicker
                  color={iconColor}
                  onChangeComplete={(color) => {
                    setIconColor(color.hex);
                  }}
                />
              </div>
              <div className="dash-block">
                <p className="no dash-label">Additional Instructions</p>
                <p className="no dash-detail">
                  You will be using the original email to sign in. However, this
                  email will be used for any submissions through the chat.
                </p>
                <textarea
                  className="dash-textarea jakarta"
                  id="taInstructions"
                  placeholder="Enter additional instructions for Coco. (ex. Store hours, wifi, outdoor patio, etc.). Use complete sentences for clarity."
                ></textarea>
              </div>
              <button
                className="dash-form-btn"
                onClick={() => {
                  onSave();
                }}
              >
                Save Changes
              </button>
            </div>
          </div>
        )}
        <div className="dash-right">
          <div className="dash-preview">
            <p className="no text-center">preview</p>
          </div>
          {me.BusinessName !== undefined && (
            <iframe
              src={`https://chattingwithcoco.com/${me.BusinessName.replaceAll(
                " ",
                "-"
              )}`}
            />
          )}
        </div>
      </div>
    </div>
  );
}

import emailjs from "@emailjs/browser";
import { emailjsPublicKey } from "./constants";
import axios from "axios";

export const serverURL = "https://buttered-candied-pipe.glitch.me";

export function getTimeString() {
  const date = new Date();
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  const adjustedHours = hours % 12 || 12; // Convert to 12-hour format (12 for midnight/noon)

  return `${adjustedHours}:${minutes} ${ampm}`;
}
export function randomString(length) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charactersLength);
    result += characters[randomIndex];
  }

  return result;
}
export function removeDuplicatesByProperty(array, property) {
  const seen = new Set();
  return array.filter((item) => {
    const value = item[property];
    if (seen.has(value)) {
      return false;
    } else {
      seen.add(value);
      return true;
    }
  });
}
export function removeDuplicates(array) {
  const seen = new Set();
  return array.filter((item) => {
    if (seen.has(item)) {
      return false;
    } else {
      seen.add(item);
      return true;
    }
  });
}

//

export function callNumber(phoneNumber) {
  window.location.href = `tel:${phoneNumber}`;
}

//

export async function emailjs_SubmitComplaint(
  toEmail,
  fromEmail,
  fromName,
  complaint,
  imageUrl,
  success
) {
  await emailjs
    .send(
      "bagel_coco_service",
      "template_uufja3l",
      {
        toEmail: toEmail,
        fromName: fromName,
        complaint: complaint,
        fromEmail: fromEmail,
        replyTo: fromEmail,
        imageUrl: imageUrl,
      },
      emailjsPublicKey
    )
    .then(() => {
      success(true);
    })
    .catch(() => {
      success(false);
    });
}
export async function emailjs_SubmitContact(
  toEmail,
  fromEmail,
  fromName,
  message,
  success
) {
  await emailjs
    .send(
      "bagel_coco_service",
      "template_0l7o91l",
      {
        toEmail: toEmail,
        fromName: fromName,
        subject: "Contact Form Submission - Chatting With Coco",
        message: message,
        fromEmail: fromEmail,
        replyTo: fromEmail,
      },
      emailjsPublicKey
    )
    .then(() => {
      success(true);
    })
    .catch((error) => {
      console.log(error);
      success(false);
    });
}
export async function emailjs_SendEmail(
  toEmail,
  fromEmail,
  fromName,
  subject,
  message,
  success
) {
  await emailjs
    .send(
      "bagel_coco_service",
      "template_0l7o91l",
      {
        toEmail: toEmail,
        fromName: fromName,
        subject: subject,
        message: message,
        fromEmail: fromEmail,
        replyTo: fromEmail,
      },
      emailjsPublicKey
    )
    .then(() => {
      success(true);
    })
    .catch((error) => {
      console.log(error);
      success(false);
    });
}

export async function function_textToSpeech(text, setter) {
  const body = { text: text };
  const url = `${serverURL}/synthesize`;

  try {
    const response = await axios.post(url, body, { responseType: "blob" });
    const blob = new Blob([response.data], { type: "audio/mp3" });
    const audioUrl = URL.createObjectURL(blob);
    setter(audioUrl);
  } catch (error) {
    console.error("Error synthesizing speech:", error);
    throw error;
  }
}
export async function function_speechToText(audioBlob, setter) {
  const formData = new FormData();
  formData.append('audio', audioBlob);

  const url = `${serverURL}/transcribe`;

  try {
    const response = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    const transcription = response.data.transcription;
    setter(transcription);
  } catch (error) {
    console.error('Error transcribing speech:', error);
  }
}

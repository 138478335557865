import React, { useState, useEffect } from "react";
import "./STYLES/SignUp.css";
import avatarGif from './coco.gif'
import Footer from "./UTILITIES/Footer";
import { useNavigate } from "react-router-dom";

function Typewriter({ text, classes }) {
  const [displayText, setDisplayText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (text !== undefined && currentIndex < text.length) {
      const timeout = setTimeout(() => {
        let nextChar = text[currentIndex];

        // Handle newline characters
        if (nextChar === "\n") {
          setDisplayText((prevText) => prevText + "<br />");
        } else {
          setDisplayText((prevText) => prevText + nextChar);
        }

        setCurrentIndex(currentIndex + 1);
      }, 100); // Typing speed in milliseconds (adjust as needed)

      return () => clearTimeout(timeout);
    }
  }, [text, currentIndex]);

  return (
    <span
      className={classes}
      dangerouslySetInnerHTML={{ __html: displayText }}
    />
  );
}

export default function SignUp() {
  const navigate = useNavigate()
  return (
    <div className="main jakarta">
      <div className="signup-main">
        <div className="signup-left">
          <h1 className="no signup-title pointer" onClick={() => {
            navigate("/")
          }}>Chatting with Coco</h1>
          <br />
          <p className="no signup-caption">
            <Typewriter text={"Hire Coco today!"} />
          </p>
          <br />
          <div className="signup-block-wrap">
            <div className="signup-block">
              <h3 className="no">Coco knows it all!</h3>
              <br />
              <p className="no">
                Coco is taught to help you in any way possible. She knows the
                best ways to give the customer information, collection
                information for contact or complaint, and even take orders. You
                can even feed her additional instructions for better and more
                relevant results.
              </p>
            </div>
            <div className="signup-block">
              <h3 className="no">Your most affordable employee.</h3>
              <br />
              <p className="no">
                With just <b>$10 / month</b>, Coco can assist you in your most
                annoying customer service problems. In addition, we do charge{" "}
                <b>$0.05 per conversation</b> or{" "}
                <b>$0.10 per conversation with a successful order</b>. We work
                hard to optimize Coco's knowledge and add more features to
                benefit your business.
              </p>
            </div>
            <div className="signup-block">
              <h3 className="no">Easy activation and set up!</h3>
              <br />
              <p className="no">
                We make it easy for you to get started with your own chat bot,
                and it is activated at an intant. As soon as you create an
                account, we will give you a code snippet and a link which you
                can embed into your own site.
              </p>
            </div>
          </div>
        </div>
        <div className="signup-right">
            <div className="signup-avatar">
                <img src={avatarGif} className="signup-avatar-gif" />
            </div>
          <h1 className="no signup-form-title">Sign Up</h1>
          <br />
          <p className="no blue">
            Please fill out all details properly. If you give the wrong API key,
            or instructions, Coco may not perform as well.
          </p>
          <br />
          <div className="signup-form">
            <p className="no signup-label">Business Name</p>
            <input
              type="text"
              id="tbBusinessName"
              className="signup-form-input"
              placeholder="Business Name"
            />
            <p className="no signup-label">Business Email</p>
            <input
              type="text"
              id="tbEmail"
              className="signup-form-input"
              placeholder="Business Email"
            />
            <p className="no signup-label">Square API Key</p>
            <input
              type="text"
              id="tbEmail"
              className="signup-form-input"
              placeholder="Enter API Key here.."
            />
            <p className="no signup-label">Additional Instructions</p>
            <textarea
              className="signup-form-textarea jakarta"
              id="taInstructions"
              placeholder="Enter additional instructions for Coco. (ex. Store hours, wifi, outdoor patio, etc.). Use complete sentences for clarity."
            ></textarea>
            <button className="signup-form-btn">Let's Go!</button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

import React, { useState } from "react";
import { function_textToSpeech } from "./functions";

export default function Test() {
  return (
    <div>
      <TextToSpeech />
    </div>
  );
}
const TextToSpeech = () => {
  const [text, setText] = useState("");
  const [audioUrl, setAudioUrl] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await function_textToSpeech(text, setAudioUrl);
    } catch (error) {
      console.error("Error synthesizing speech:", error);
    }
  };

  const handlePlay = () => {
    const audio = new Audio(audioUrl);
    audio.play();
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <textarea value={text} onChange={(e) => setText(e.target.value)} />
        <button onClick={() => {}}>Convert to Speech</button>
      </form>
      {audioUrl && (
        <div>
          <button onClick={handlePlay}>Play Audio</button>
        </div>
      )}
    </div>
  );
};

import React, { useState, useEffect } from "react";
import "./STYLES/LogIn.css";
import { useNavigate } from "react-router-dom";
import { auth_SignIn } from "./firebase";
import Footer from "./UTILITIES/Footer";

function Typewriter({ text, classes }) {
  const [displayText, setDisplayText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (text !== undefined && currentIndex < text.length) {
      const timeout = setTimeout(() => {
        let nextChar = text[currentIndex];

        // Handle newline characters
        if (nextChar === "\n") {
          setDisplayText((prevText) => prevText + "<br />");
        } else {
          setDisplayText((prevText) => prevText + nextChar);
        }

        setCurrentIndex(currentIndex + 1);
      }, 100); // Typing speed in milliseconds (adjust as needed)

      return () => clearTimeout(timeout);
    }
  }, [text, currentIndex]);

  return (
    <span
      className={classes}
      dangerouslySetInnerHTML={{ __html: displayText }}
    />
  );
}

export default function LogIn() {
  const navigate = useNavigate();

  function onLogin() {
    const email = document.querySelector("#tbEmail").value;
    const password = document.querySelector("#tbPassword").value;

    auth_SignIn(email, password, (things) => {
      navigate(`/dashboard`);
    });
  }
  return (
    <div className="main jakarta">
      <div className="login-main">
        {/*  */}
        <div>
        <h1 className="no signup-title pointer" onClick={() => {
            navigate("/")
          }}>Chatting with Coco</h1>
          <br />
          <p className="no signup-caption">
            <Typewriter text={"Welcome back, partner."} />
          </p>
        </div>
        {/*  */}
        <div className="login-form">
          <p className="no login-title">Login</p>
          <br />
          <p className="no login-label">Email</p>
          <input
            type="text"
            className="login-input"
            id="tbEmail"
            placeholder="jdoe@chatting.com"
          />
          <p className="no login-label">Password</p>
          <input
            type="password"
            className="login-input"
            id="tbPassword"
            placeholder="********"
          />
          <br />
          <br />
          <button onClick={onLogin} className="login-btn">
            Log In
          </button>
        </div>
        {/*  */}
        <div className="text-center">
          <p>
            Not a member?{" "}
            <span
              onClick={() => {
                navigate("/signup");
              }}
              className="underline pointer"
            >
              sign up.
            </span>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

import React, { useState, useEffect } from "react";
import Footer from "./UTILITIES/Footer";
import "./STYLES/Contact.css";
import { useNavigate } from "react-router-dom";
import avatarGif from "./coco.gif";
import { emailjs_SendEmail } from "./functions";

function Typewriter({ text, classes }) {
  const [displayText, setDisplayText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (text !== undefined && currentIndex < text.length) {
      const timeout = setTimeout(() => {
        let nextChar = text[currentIndex];

        // Handle newline characters
        if (nextChar === "\n") {
          setDisplayText((prevText) => prevText + "<br />");
        } else {
          setDisplayText((prevText) => prevText + nextChar);
        }

        setCurrentIndex(currentIndex + 1);
      }, 100); // Typing speed in milliseconds (adjust as needed)

      return () => clearTimeout(timeout);
    }
  }, [text, currentIndex]);

  return (
    <span
      className={classes}
      dangerouslySetInnerHTML={{ __html: displayText }}
    />
  );
}

function onSendForm() {
  const fullName = document.querySelector("#tbName").value;
  const email = document.querySelector("#tbEmail").value;
  const subject = document.querySelector("#ddSubject").value;
  const message = document.querySelector("#taMessage").value;

  if (fullName !== "" && email !== "" && subject !== "Select One" && message !== "") {
    emailjs_SendEmail(
      "bagel.inbox@gmail.com",
      email,
      fullName,
      subject,
      message,
      (success) => {
        if (success) {
          document.querySelector("#tbName").value = "";
          document.querySelector("#tbEmail").value = "";
          document.querySelector("#ddSubject").value = "";
          document.querySelector("#taMessage").value = "";
  
          alert("SUCCESS! We will contact you shortly.")
        } else {
          alert("There was an issue. Try again later.");
        }
      }
    );
  } else {
    alert("Please fill out all fields.")
  }
}

export default function Contact() {
  const navigate = useNavigate();
  return (
    <div className="main jakarta">
      <div className="contact-main">
        <div className="contact-left">
          <h1
            className="no signup-title pointer"
            onClick={() => {
              navigate("/");
            }}
          >
            Chatting with Coco
          </h1>
          <br />
          <Typewriter
            text={"Ask us any questions."}
            classes={"contact-title"}
          />
          <br />
          <div className="spacer"></div>
          <div className="contact-section">
            <p className="no">Email</p>
            <Typewriter
              text={"contact\n@chattingwithcoco.com"}
              classes={"no contact-method"}
            />
          </div>
          <br />
          <div className="contact-section">
            <p className="no">Hours</p>
            <Typewriter
              text={"Everyday\n 12pm - 6pm Pacific"}
              classes={"no contact-method"}
            />
          </div>
        </div>
        <div className="contact-right">
          <img src={avatarGif} className="contact-avatar" />
          <p className="no contact-form-caption">
            Best way to ask a question or reach out to us is by using this
            contact form.
          </p>
          <br />
          <div className="contact-form">
            {/*  */}
            <div className="contact-block">
              <p className="no">Name</p>
              <input
              id="tbName"
                type="text"
                className="contact-input"
                placeholder="Your full name"
              />
            </div>
            {/*  */}
            <div className="contact-block">
              <p className="no">Email</p>
              <input
              id="tbEmail"
                type="text"
                className="contact-input"
                placeholder="Email"
              />
            </div>
            {/*  */}
            <div className="contact-block">
              <p className="no">Subject</p>
              <select id="ddSubject">
                <option>Select One</option>
                <option>Coco Chat Bot</option>
                <option>Account</option>
                <option>General Questions</option>
                <option>Comment</option>
              </select>
            </div>
            {/*  */}
            <div className="contact-block">
              <p className="no">Message</p>
              <textarea
                className="contact-textarea jakarta"
                id="taMessage"
                placeholder="Type your message here..."
              ></textarea>
            </div>
            <br />
            <button className="contact-btn" onClick={onSendForm}>Submit</button>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

import React, { useEffect, useState } from "react";
import "./STYLES/Home.css";
import { FaStarOfLife } from "react-icons/fa";
import avatarGif from "./coco.gif";
import { MdArrowOutward } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Footer from "./UTILITIES/Footer";

function Typewriter({ text, classes }) {
  const [displayText, setDisplayText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (text !== undefined && currentIndex < text.length) {
      const timeout = setTimeout(() => {
        let nextChar = text[currentIndex];

        // Handle newline characters
        if (nextChar === "\n") {
          setDisplayText((prevText) => prevText + "<br />");
        } else {
          setDisplayText((prevText) => prevText + nextChar);
        }

        setCurrentIndex(currentIndex + 1);
      }, 100); // Typing speed in milliseconds (adjust as needed)

      return () => clearTimeout(timeout);
    }
  }, [text, currentIndex]);

  return (
    <span
      className={classes}
      dangerouslySetInnerHTML={{ __html: displayText }}
    />
  );
}

export default function Home() {
  const navigate = useNavigate();
  return (
    <div className="main">
      <div className="bagel jakarta">
        <div className="home-left">
          <div className="home-title-wrap">
            <h1 className="no">
              <Typewriter
                classes={"home-title"}
                text={"Chatting\n With\n Coco..\n"}
              />
              <Typewriter
                classes={"keyword"}
                text={"AI chat for business customers."}
              />
            </h1>
            <div className="home-caption-wrap">
              <FaStarOfLife />
              <p className="no home-caption">
                Your business can have its own{" "}
                <span className="keyword">AI Chat </span>to{" "}
                <span className="keyword">answer questions</span>,{" "}
                <span className="keyword">add items to bag</span>,{" "}
                <span className="keyword">create orders</span>,{" "}
                <span className="keyword">send deliveries</span>, and more!
              </p>
            </div>
            <div className="home-menu">
              <div
                className="separate_h home-menu-link pointer"
                onClick={() => {
                  window.open(
                    `https://chattingwithcoco.com/Coco-Demo`,
                    "_blank"
                  );
                }}
              >
                <h2 className="no">Try a demo!</h2>
                <MdArrowOutward size={25} />
              </div>
              <div
                className="separate_h home-menu-link pointer"
                onClick={() => {
                  navigate("/contact");
                }}
              >
                <h2 className="no">Contact</h2>
                <MdArrowOutward size={25} />
              </div>
              <div
                className="separate_h home-menu-link pointer"
                onClick={() => {
                  navigate("/login");
                }}
              >
                <h2 className="no">Log In</h2>
                <MdArrowOutward size={25} />
              </div>
              <div
                className="separate_h home-menu-link pointer"
                onClick={() => {
                  navigate("/signup");
                }}
              >
                <h2 className="no">Sign Up</h2>
                <MdArrowOutward size={25} />
              </div>
            </div>
          </div>
        </div>
        <div className="home-right">
          <img src={avatarGif} className="home-avatar" />
          <div className="home-avatar-caption">
            <p className="no">
              <span className="keyword">Meet Coco</span>, your dedicated
              business customer service host. Coco is an invaluable asset,
              providing a seamless experience that rivals interacting with a
              real person. Say goodbye to phone calls, complaints, wasted time,
              and confusion. Coco tirelessly works to ensure your growth and
              success, making your customer service experience effortless and
              efficient.{" "}
              <span
                style={{ textDecoration: "underline" }}
                onClick={() => {
                  window.open(
                    "https://chattingwithcoco.com/Coco-Demo",
                    "_blank"
                  );
                }}
              >
                Chat with Coco now!
              </span>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

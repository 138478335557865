export const serverURL = "https://buttered-candied-pipe.glitch.me";

export async function stripe_GetPaymentUrl(cents, url) {
    try {
      const response = await fetch(`${serverURL}/stripe-payment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ cents })
      });
  
      if (!response.ok) {
        throw new Error(`Server error: ${response.status}`);
      }
  
      const data = await response.json();
      url(data.url)
    } catch (error) {
      console.error('Error fetching payment URL:', error);
      throw error;
    }
  }
import {
  GoogleGenerativeAI,
  HarmBlockThreshold,
  HarmCategory,
  HarmProbability,
} from "@google/generative-ai";
import {
  function_textToSpeech,
  getTimeString,
  randomString,
} from "./functions";

//
const genAI = new GoogleGenerativeAI(process.env.REACT_APP_GEMINI_KEY);

const structure_addToBag = {
  name: "addToBag",
  parameters: {
    type: "OBJECT",
    description: "Adds the item to their bag.",
    properties: {
      itemId: {
        type: "STRING",
        description: "Get the id of the item that matches the VARIATIONS.",
      },
      name: {
        type: "STRING",
        description: "Get the name of the item based on the menu.",
      },
      quantity: {
        type: "INTEGER",
        description: "Get the quantity the customer wants for this item.",
      },
      total: {
        type: "NUMBER",
        description:
          "Get the variation price the customer wants for this item.",
      },
      note: {
        type: "STRING",
        description:
          'Optionally place any special requests concerning this bag item. comma separated. Leave blank if they do not have any special requests. ""',
      },
      itemOptions: {
        type: "ARRAY",
        description: "Return the chosen item option details.",
        items: {
          type: "OBJECT",
          properties: {
            id: {
              type: "STRING",
              description: "The id of the item option chosen by the customer.",
            },
            quantity: {
              type: "INTEGER",
              description:
                "The quantity of the item option chosen by the customer.",
            },
            name: {
              type: "STRING",
              description:
                "The name of the item option chosen by the customer.",
            },
          },
          required: ["id", "quantity", "name"],
        },
      },
      modifiers: {
        type: "ARRAY",
        description: "Return the chosen modifier option details.",
        items: {
          type: "OBJECT",
          properties: {
            id: {
              type: "STRING",
              description:
                "The id of the modifier option chosen by the customer.",
            },
            quantity: {
              type: "INTEGER",
              description:
                "The quantity of the modifier option chosen by the customer.",
            },
            name: {
              type: "STRING",
              description:
                "The name of the modifier option chosen by the customer.",
            },
            price: {
              type: "NUMBER",
              description:
                "The price of the modifier option chosen by the customer.",
            },
          },
          required: ["id", "quantity", "name", "price"],
        },
      },
    },
    required: ["itemId", "name", "quantity", "total"],
  },
};
const structure_updateBag = {
  name: "updateBag",
  parameters: {
    type: "OBJECT",
    description:
      "Adds an item to the cart depending on what the user wants to order. If the item has options, wait until the option has been confirmed by the customer.",
    properties: {
      itemId: {
        type: "STRING",
        description: "Get the id of the item that matches the VARIATIONS.",
      },
      name: {
        type: "STRING",
        description: "Get the name of the item based on the menu.",
      },
      quantity: {
        type: "INTEGER",
        description: "Get the quantity the customer wants for this item.",
      },
      total: {
        type: "NUMBER",
        description:
          "Get the variation price the customer wants for this item.",
      },
      note: {
        type: "STRING",
        description:
          'Optionally place any special requests concerning this bag item. comma separated. Leave blank if they do not have any special requests. ""',
      },
      itemOptions: {
        type: "ARRAY",
        description: "Return the chosen item option details.",
        items: {
          type: "OBJECT",
          properties: {
            id: {
              type: "STRING",
              description: "The id of the item option chosen by the customer.",
            },
            quantity: {
              type: "INTEGER",
              description:
                "The quantity of the item option chosen by the customer.",
            },
            name: {
              type: "STRING",
              description:
                "The name of the item option chosen by the customer.",
            },
          },
          required: ["id", "quantity", "name"],
        },
      },
      modifiers: {
        type: "ARRAY",
        description: "Return the chosen modifier option details.",
        items: {
          type: "OBJECT",
          properties: {
            id: {
              type: "STRING",
              description:
                "The id of the modifier option chosen by the customer.",
            },
            quantity: {
              type: "INTEGER",
              description:
                "The quantity of the modifier option chosen by the customer.",
            },
            name: {
              type: "STRING",
              description:
                "The name of the modifier option chosen by the customer.",
            },
            price: {
              type: "NUMBER",
              description:
                "The price of the modifier option chosen by the customer.",
            },
          },
          required: ["id", "quantity", "name", "price"],
        },
      },
    },
    required: ["itemId", "name", "quantity", "total"],
  },
};
const structure_removeFromBag = {
  name: "removeFromBag",
  parameters: {
    type: "OBJECT",
    description:
      "Returns the id of the item that the customer wants to remove from their bag.",
    properties: {
      itemId: {
        type: "STRING",
        description: "Get the id of the item based on the menu.",
      },
    },
    required: ["itemId"],
  },
};
const structure_createOrder = {
  name: "createOrder",
  parameters: {
    type: "OBJECT",
    description:
      "Puts together all of the stored bag items and prepares it for creating an order for payment.",
    properties: {
      locationId: {
        type: "STRING",
        description:
          "Based on the menu, find the locationId and place in here.",
      },
      lineItems: {
        type: "ARRAY",
        description: "Array of items in the order.",
        items: {
          type: "OBJECT",
          properties: {
            catalogObjectId: {
              type: "STRING",
              description: "The id of the item",
            },
            name: {
              type: "STRING",
              description: "The name of the item",
            },
            quantity: {
              type: "INTEGER",
              description: "The quantity of the item being ordered.",
            },
            basePriceMoney: {
              type: "OBJECT",
              description:
                "The base price of the item in smallest currency unit.",
              properties: {
                amount: {
                  type: "INTEGER",
                  description:
                    "The amount for 1 of this item NOT THE TOTAL only for the price of one, in the smallest unit of the currency (e.g., cents for USD).",
                },
                currency: {
                  type: "STRING",
                  description: "The currency of the money (e.g., USD).",
                },
              },
              required: ["amount", "currency"],
            },
            modifiers: {
              type: "ARRAY",
              description: "Array of modifiers for the item.",
              items: {
                type: "OBJECT",
                properties: {
                  catalogObjectId: {
                    type: "STRING",
                    description: "The id of this modifier.",
                  },
                  name: {
                    type: "STRING",
                    description: "The name of the modifier.",
                  },
                  quantity: {
                    type: "INTEGER",
                    description: "The quantity of the modifier.",
                  },
                  basePriceMoney: {
                    type: "OBJECT",
                    description:
                      "The price of ONE of these modifiers in pennies",
                    properties: {
                      amount: {
                        type: "INTEGER",
                        description:
                          "the price of the individual modifier that the customer chose. in the smallest unit of the currency (e.g., cents for USD).",
                      },
                      currency: {
                        type: "STRING",
                        description: "The currency of the money (e.g., USD).",
                      },
                    },
                    required: ["amount", "currency"],
                  },
                },
                required: [
                  "catalogObjectId",
                  "name",
                  "quantity",
                  "basePriceMoney",
                ],
              },
            },
            special_instructions: {
              type: "STRING",
              description: "Special instructions for the item.",
            },
          },
          required: ["catalogObjectId", "quantity", "basePriceMoney"],
        },
      },
    },
    required: ["locationId", "lineItems"],
  },
};
const structure_showComplaintForm = {
  name: "showComplaintForm",
  parameters: {
    type: "OBJECT",
    description:
      "Calls when the customer wants to fill out a complaint form. BEFORE they enter details.",
    properties: {},
    required: [],
  },
};
const structure_showContactForm = {
  name: "showContactForm",
  parameters: {
    type: "OBJECT",
    description:
      "Calls when the customer wants to contact the business. Call this function BEFORE they enter any details.",
    properties: {},
    required: [],
  },
};
const structure_showMenu = {
  name: "showMenu",
  parameters: {
    type: "OBJECT",
    description:
      "Call this function instead of typing out the entire menu. Instead say, what would you like to order?",
    properties: {},
    required: [],
  },
};
const structure_giveOptions = {
  name: "giveOptions",
  parameters: {
    type: "OBJECT",
    description:
      "Calls when the customer needs to choose between several item options if the menu item has any.",
    properties: {
      options: {
        type: "ARRAY",
        description:
          "The item options for the menu item. For example, 8oz, 10oz, 12oz. Only the name of the option, not the entire option.",
      },
    },
    required: ["options"],
  },
};
const structure_showQuantity = {
  name: "showQuantity",
  parameters: {
    type: "OBJECT",
    description:
      "Give the customer options for how many quantity they can order.",
    properties: {
      quantity: {
        type: "ARRAY",
        description: "Array of the numbers one through 10.",
      },
    },
    required: ["quantity"],
  },
};
const structure_yesOrNo = {
  name: "yesOrNo",
  parameters: {
    type: "OBJECT",
    description:
      "Give the customer yes or no options to answer a yes or no question.",
    properties: {
      options: {
        type: "ARRAY",
        description: "This should be Yes and No",
      },
    },
    required: ["options"],
  },
};

// GEMINI FUNCTIONS
export async function gemini_StartChat(instructions, setter) {
  const model = genAI.getGenerativeModel({
    model: "gemini-1.5-flash-latest",
    systemInstruction: instructions,
    tools: {
      functionDeclarations: [
        structure_addToBag,
        structure_updateBag,
        structure_removeFromBag,
        structure_createOrder,
        structure_showComplaintForm,
        structure_showContactForm,
        structure_showMenu,
        structure_giveOptions,
        structure_showQuantity,
        structure_yesOrNo,
      ],
    },
    // SAFETY SETTINGS
    safetySettings: [
      {
        category: HarmCategory.HARM_CATEGORY_HARASSMENT,
        threshold: HarmBlockThreshold.BLOCK_NONE,
      },
      {
        category: HarmCategory.HARM_CATEGORY_HATE_SPEECH,
        threshold: HarmBlockThreshold.BLOCK_NONE,
      },
      {
        category: HarmCategory.HARM_CATEGORY_DANGEROUS_CONTENT,
        threshold: HarmBlockThreshold.BLOCK_NONE,
      },
      {
        category: HarmCategory.HARM_CATEGORY_SEXUALLY_EXPLICIT,
        threshold: HarmBlockThreshold.BLOCK_NONE,
      },
    ],
  });
  const chat = model.startChat({
    history: [],
    generationConfig: {
      maxOutputTokens: 200,
    },
  });
  setter(chat);
}
export async function gemini_SendMessage(
  chat,
  input,
  functions,
  toggleVoice,
  setter
) {
  try {
    // Send initial message and get result
    const result = await chat.sendMessageStream(input);

    // Process chunks of initial response
    let text = "";
    const firstId = randomString(25);
    for await (const chunk of result.stream) {
      const chunkText = chunk.text();
      text += chunkText;
      setter({
        role: "gemini",
        message: text,
        date: getTimeString(),
        id: firstId,
      });
    }
    if (toggleVoice) {
      function_textToSpeech(text, (speech) => {
        const audio = new Audio(speech);
        audio.play();
      });
    }
    // Get response object to check for function calls
    const response = await result.response;
    const calls = await response.functionCalls();

    // If there are function calls, process them
    if (calls && calls.length > 0) {
      console.log("FUNCTION CALLS:", calls);

      // Handle each function call sequentially
      const functionName = calls[0].name;
      const args = calls[0].args;
      if (functions[functionName]) {
        console.log(`Calling function: ${functionName} with arguments:`, args);

        // Call the function and get API response
        try {
          const apiResponse = await functions[functionName](args);
        } catch (error) {
          console.error("Error calling function:", functionName, error);
          // Handle function call error (optional: send error message to user)
        }
      } else {
        console.warn(`Function not found: ${functionName}`);
      }
    } else {
      console.log("No function calls found");
    }
  } catch (error) {
    console.error("Error in gemini_SendMessage:", error);
    // Handle error gracefully, e.g., display a user-friendly message
  }
}

import { useEffect, useRef, useState } from "react";
import "./App.css";
import "./Chat.css";
import { gemini_SendMessage, gemini_StartChat } from "./gemini";
import { useParams } from "react-router-dom";
import {
  auth_VerifyPhone,
  firebase_GetAccount,
  storage_UploadImage,
} from "./firebase";
import { square_CreateOrder, square_GetMenu } from "./square";
import { FaCircleArrowUp, FaStop, FaXmark } from "react-icons/fa6";
import { GoPersonFill } from "react-icons/go";
import {
  BiChevronRight,
  BiPhoneCall,
  BiSolidHappyBeaming,
} from "react-icons/bi";
import { BiSolidFoodMenu } from "react-icons/bi";
import {
  callNumber,
  emailjs_SubmitComplaint,
  emailjs_SubmitContact,
  function_speechToText,
  function_textToSpeech,
  getTimeString,
  randomString,
  removeDuplicates,
} from "./functions";
import { MdShoppingBag } from "react-icons/md";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import cocoGif from "./coco.gif";
import thinkingGif from "./thinking.gif";
import { FaMicrophone } from "react-icons/fa6";
import { FaICursor } from "react-icons/fa";
import {
  BsArrowRight,
  BsArrowRightCircle,
  BsArrowRightCircleFill,
} from "react-icons/bs";

function Typewriter({ text }) {
  const [displayText, setDisplayText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (text !== undefined && currentIndex < text.length) {
      const timeout = setTimeout(() => {
        // Update displayText with the next character
        setDisplayText((prevText) => prevText + text[currentIndex]);

        // Handle replacements for * and **
        if (text[currentIndex] === "*") {
          // Check if next character is also *
          if (text[currentIndex + 1] === "*") {
            setDisplayText((prevText) =>
              prevText.replace(/\*\*(.*?)\*\*/, "<b>$1</b>")
            );
            setCurrentIndex((prevIndex) => prevIndex + 2); // Skip past the **
          } else {
            setDisplayText((prevText) => prevText.replace(/\*(?!\*)/, "•")); // Replace single * not followed by another *
            setCurrentIndex((prevIndex) => prevIndex + 1);
          }
        } else {
          setCurrentIndex((prevIndex) => prevIndex + 1);
        }
      }, 10); // Typing speed in milliseconds (adjust as needed)

      return () => clearTimeout(timeout);
    }
  }, [text, currentIndex]);

  return <>{displayText.trim()}</>;
}

function App() {
  const { businessName } = useParams();
  const chatWrapperRef = useRef(null);
  const isFirstRender = useRef(true);
  const inputRef = useRef(null);
  const [account, setAccount] = useState({});
  const [chat, setChat] = useState(null);
  const [messages, setMessages] = useState([
    {
      id: "1",
      role: "gemini",
      message: `Hello, I'm Coco, here to answer any questions or take your order on behalf of ${businessName.replaceAll(
        "-",
        " "
      )}. Give me a moment while I pull up the menu.`,
      date: getTimeString(),
    },
  ]);
  const [isChatReady, setIsChatReady] = useState(false);
  const [bagItems, setBagItems] = useState([]);
  const [showBagItems, setShowBagItems] = useState(false);
  const [showFAQ, setShowFAQ] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [toggleChatMenu, setToggleChatMenu] = useState(false);
  const [showLoadingMenu, setShowLoadingMenu] = useState(true);
  const [squareMenu, setSquareMenu] = useState([]);
  const [buttonOptions, setButtonOptions] = useState([]);
  const [url, setUrl] = useState("");
  const [image, setImage] = useState(null);
  const faqs = [
    "Can I see your menu?",
    "Can I make a reservation?",
    "What are your hours of operation?",
    "What are your specials today?",
    "Do you have vegetarian/vegan options?",
    "Do you offer takeout?",
    "Do you offer delivery?",
    "Do you take reservations?",
    "Do you have a kids' menu?",
    "Can you accommodate food allergies?",
    "Do you have gluten-free options?",
    "Can you recommend something?",
    "Do you have outdoor seating?",
    "Is there parking available?",
    "Do you offer gift cards?",
    "Do you offer any discounts or promotions?",
    "What desserts do you have?",
    "Do you have Wi-Fi?",
    "Are pets allowed?",
    "Do you host events or parties?",
    "Do you cater?",
    "What’s the history of this restaurant?",
    "I would like to contact the business",
    "Can I submit a complaint?",
  ];
  const [showComplaintForm, setShowComplaintForm] = useState(false);
  const [showContactForm, setShowContactForm] = useState(false);
  const [thinking, setThinking] = useState(true);
  const [toggleVoice, setToggleVoice] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [voiceRecording, setVoiceRecording] = useState(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  const textInstructions = `MAIN INSTRUCTIONS:
  - Your name is Coco, but dont say Coco:.
  - When taking their order, ask them for one detail at a time. First quantity, then options, then modifiers only if they have any.
  - If you do not know the answer, let them know that you do not know at the moment. Then ask if they would like to order or ask any other question.
  - If they ask for a request that is not on the menu, ask if they want to add it as a note. If yes, then add it as a note when you call the addToBag function.

  FUNCTIONS:
  showQuantity: Call showQuantity before you ask the customer how many they want to order.
  giveOptions: Call giveOptions before you ask the customer what options they want for the menu item. Only the name of the item, not the entire object.
  showMenu: Call showMenu ONLY when the customer asks to see the menu, not if they as to order. Then ask what they would like to order.
  yesOrNo: Call yesOrNo if the customer can answer the question with a simple yes or a no answer. 
  addToBag: Call addToBag when the customer has confirmed that they want to add the item to their bag. Then ask if they want to order anything else.
    - Before you call addToBag, make sure you have the quantity, and any options or modifiers (if the item has any options or modifiers).
    - Before you call addToBag, confirm if they want to add the item to their bag; once you have the quantity, options, and modifiers if the item has any.
  updateBag: Call updateBag if the item is already in the bag. The customer can update the quantity, options, or modifiers of the bag item.
  removeFromBag: Call removeFromBag if the customer wants to remove an item from their bag.
    - Confirm before removing.
  createOrder: Call createOrder function if the customer wants to proceed to pay for their bag items. Confirm with them if they are ready to pay for their order.
    - Only if they have more than one bag item in cents. For example, if the price is $6, then pass 600.
    - the base price should be the price of only one of that item in cents. For example, if the price is $2, then pass 200.
    - Once the function is called, the customer will be redirected to the payment page.
  showComplaintForm: Call showComplaintForm is the customer wants to submit a complaint to the business.
  showContactForm: Call showContactForm is the customer wants to contact the business.

  Today is ${new Date()}
  `;
  const functions = {
    addToBag: addToBag,
    updateBag: updateBag,
    removeFromBag: removeFromBag,
    createOrder: createOrder,
    showComplaintForm: onShowComplaintForm,
    showContactForm: onShowContactForm,
    showMenu: onShowMenu,
    giveOptions: onGiveOptions,
    showQuantity: onShowQuantity,
    yesOrNo: onYesOrNo,
  };
  // LOCAL FUNCTIONS
  function sendMessage() {
    // THINKING
    setThinking(true);
    const message = document.querySelector("#tbMessage").value;
    if (message !== "" && chat) {
      setButtonOptions([]);
      document.querySelector("#tbMessage").value = "";
      setMessages((prev) => [
        ...prev,
        {
          id: randomString(25),
          role: "user",
          message: message,
          date: getTimeString(),
        },
      ]);
      gemini_SendMessage(chat, message, functions, toggleVoice, (response) => {
        const responseId = response.id;
        setMessages((prev) => {
          // Check if responseId exists in the array
          const index = prev.findIndex((msg) => msg.id === responseId);

          if (index !== -1) {
            // If responseId exists, update the message
            return prev.map((msg) => {
              if (msg.id === responseId) {
                return response; // Replace the message with response
              }
              return msg; // If id does not match, return the original message unchanged
            });
          } else {
            // If responseId does not exist, append response to the array
            return [...prev, response];
          }
        });
        // THINKING
        setThinking(false);
      });
    }
  }
  function sendPromptMessage(prompt) {
    if (chat) {
      setThinking(true);
      setButtonOptions([]);
      setMessages((prev) => [
        ...prev,
        {
          id: randomString(25),
          role: "user",
          message: prompt,
          date: getTimeString(),
        },
      ]);

      gemini_SendMessage(chat, prompt, functions, toggleVoice, (response) => {
        const responseId = response.id;

        setMessages((prev) => {
          // Check if responseId exists in the array
          const index = prev.findIndex((msg) => msg.id === responseId);

          if (index !== -1) {
            // If responseId exists, update the message
            return prev.map((msg) => {
              if (msg.id === responseId) {
                return response; // Replace the message with response
              }
              return msg; // If id does not match, return the original message unchanged
            });
          } else {
            // If responseId does not exist, append response to the array
            return [...prev, response];
          }
        });
        // THINKING
        setThinking(false);
      });
    }
  }
  function onSubmitComplaint() {
    const name = document.querySelector("#tbFullName").value;
    const message = document.querySelector("#taMessage").value;
    const email = document.querySelector("#tbEmail").value;
    const imageFile = image;
    if (imageFile !== null) {
      storage_UploadImage(imageFile, (url) => {
        emailjs_SubmitComplaint(email, name, message, url, (success) => {
          setShowComplaintForm(false);
          if (success) {
            sendPromptMessage("My submission was sent successfully!");
          } else {
            sendPromptMessage("There was a problem with my submission.");
          }
        });
      });
    } else {
      emailjs_SubmitComplaint(
        email,
        name,
        message,
        "https://firebasestorage.googleapis.com/v0/b/chat-with-coco-3d9b0.appspot.com/o/Images%2F5.png?alt=media&token=5f72b621-255b-4de8-b10d-b4a47041eca0",
        (success) => {
          setShowComplaintForm(false);
          if (success) {
            sendPromptMessage("My submission was sent successfully!");
          } else {
            sendPromptMessage("There was a problem with my submission.");
          }
        }
      );
    }
  }
  function onPickImage(e) {
    const file = e.target.files[0];
    console.log(URL.createObjectURL(file));
    if (file) {
      setImage(file);
    } else {
      setImage(null);
    }
  }
  function onShowContactForm() {
    setShowContactForm(true);
  }
  function onSubmitContact() {
    const name = document.querySelector("#tbFullName").value;
    const message = document.querySelector("#taMessage").value;
    const email = document.querySelector("#tbEmail").value;
    emailjs_SubmitContact(account.Email, email, name, message, (success) => {
      setShowContactForm(false);
      if (success) {
        sendPromptMessage("My contact submission was sent successfully!");
      } else {
        sendPromptMessage("There was a problem with my contact submission.");
      }
    });
  }
  function onShowMenu() {
    setToggleChatMenu(true);
  }
  function onGiveOptions(args) {
    for (var i = 0; i < args.options.length; i += 1) {
      const arg = args.options[i];
      setButtonOptions((prev) => [
        ...prev,
        {
          text: arg,
          action: () => {
            sendPromptMessage(`I would like ${arg}, please.`);
            setButtonOptions([]);
          },
        },
      ]);
    }
  }
  function onYesOrNo(args) {
    console.log("YES OR NO FUNCTION");
    setButtonOptions([
      {
        text: "Yes",
        action: () => {
          sendPromptMessage("Yes, please.");
          setButtonOptions([]);
        },
      },
      {
        text: "No",
        action: () => {
          sendPromptMessage("No, thank you.");
          setButtonOptions([]);
        },
      },
    ]);
  }
  //
  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;

      let chunks = [];

      mediaRecorder.ondataavailable = (event) => {
        chunks.push(event.data);
      };

      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(chunks, { type: 'audio/mp3' });
        setVoiceRecording(audioBlob);
        chunks = [];
      };

      mediaRecorder.start();
      setIsRecording(true);
    } catch (error) {
      console.error('Error starting recording:', error);
      alert('Error starting recording: ' + error.message); // Alert the error message
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const handleRecordClick = () => {
    if (isRecording) {
      stopRecording();
    } else {
      setVoiceRecording(null); // Clear previous recording
      startRecording();
    }
  };
  // #region SHOW QUANTITY
  function onShowQuantity() {
    console.log("ON SHOW QUANTITY");
    setButtonOptions([
      {
        text: "1",
        action: () => {
          sendPromptMessage(`I want to order 1, please.`);
          setButtonOptions([]);
        },
      },
      {
        text: "2",
        action: () => {
          sendPromptMessage(`I want to order 2, please.`);
          setButtonOptions([]);
        },
      },
      {
        text: "3",
        action: () => {
          sendPromptMessage(`I want to order 3, please.`);
          setButtonOptions([]);
        },
      },
      {
        text: "4",
        action: () => {
          sendPromptMessage(`I want to order 4, please.`);
          setButtonOptions([]);
        },
      },
      {
        text: "5",
        action: () => {
          sendPromptMessage(`I want to order 5, please.`);
          setButtonOptions([]);
        },
      },
      {
        text: "6",
        action: () => {
          sendPromptMessage(`I want to order 6, please.`);
          setButtonOptions([]);
        },
      },
      {
        text: "7",
        action: () => {
          sendPromptMessage(`I want to order 7, please.`);
          setButtonOptions([]);
        },
      },
      {
        text: "8",
        action: () => {
          sendPromptMessage(`I want to order 8, please.`);
          setButtonOptions([]);
        },
      },
      {
        text: "9",
        action: () => {
          sendPromptMessage(`I want to order 9, please.`);
          setButtonOptions([]);
        },
      },
      {
        text: "10",
        action: () => {
          sendPromptMessage(`I want to order 10, please.`);
          setButtonOptions([]);
        },
      },
    ]);
  }
  // #endregion
  // GLOBAL FUNCTIONS
  function addToBag(args) {
    console.log(args);
    setBagItems((prev) => [...prev, args]);
  }
  function updateBag(args) {
    const id = args.itemId;
    setBagItems((prev) =>
      prev.map((item) => (item.itemId === id ? args : item))
    );
  }
  function removeFromBag(args) {
    const id = args.itemId;
    setBagItems((prev) => [...prev.filter((ting) => ting.itemId !== id)]);
  }
  function createOrder(args) {
    square_CreateOrder(
      account.SquareAPI,
      args.locationId,
      args.lineItems,
      (args) => {
        setUrl(args.paymentLink);
      }
    );
  }
  // function getOpenings(args) {
  //   console.log(args);
  //   yelp_GetOpenings(
  //     account.YelpAPI,
  //     account.YelpBusinessID,
  //     args.date,
  //     args.time,
  //     args.covers,
  //     true
  //   );
  // }
  function onShowComplaintForm() {
    setShowComplaintForm(true);
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        sendMessage();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [chat]);

  useEffect(() => {
    if (chatWrapperRef.current) {
      chatWrapperRef.current.scrollTo({
        top: chatWrapperRef.current.scrollHeight,
        behavior: "smooth",
      });
      console.log("Scrolled to the bottom"); // Debug log
    }
  }, [messages]);

  useEffect(() => {
    window.title = `Chatting with ${businessName.replaceAll("-", " ")}`;
    if (isFirstRender.current) {
      isFirstRender.current = false; // Set the flag to false after the first render
      window.scrollTo(0, 0);

      firebase_GetAccount(businessName.replaceAll("-", " "), (things) => {
        setAccount(things);
        square_GetMenu(things, (menu) => {
          setShowLoadingMenu(false);
          setSquareMenu(menu);
          // setShowMenu(true);
          const json = JSON.stringify(menu);
          const otherInfo =
            things.Instructions !== undefined
              ? things.Instructions.replaceAll("jjj", "\n")
              : "";
          const mainInstructions = `The name of the restaurant is: ${things.BusinessName}. ${textInstructions} MENU: ${json}. EXTRA INFO: ${otherInfo}`;
          gemini_StartChat(mainInstructions, (chat) => {
            setChat(chat);
            setIsChatReady(true);
          });
        });
      });
    }
  }, [businessName]);

  useEffect(() => {
    if (isChatReady && inputRef.current) {
      setMessages((prev) => [
        ...prev,
        {
          id: randomString(25),
          role: "gemini",
          message: "How may I help you? :)",
          date: getTimeString(),
        },
      ]);
      // inputRef.current.focus(); // Auto-focus the input field when chat is ready
      setThinking(false);
    }
  }, [isChatReady]);

  return (
    <div className="jakarta main">
      <div className="avatar">
        <img src={thinking ? thinkingGif : cocoGif} className="avatar-gif" />
      </div>
      <div
        className="chat-wrapper"
        ref={chatWrapperRef}
        style={{ overflowY: "auto", maxHeight: "90vh" }}
      >
        <div style={{ marginTop: "120px" }} />
        {messages
          .filter((ting) => ting.message !== "" && ting.message !== undefined)
          .map((mess, i) => (
            <div key={i} style={{ padding: "0" }} className="chat-pair">
              <div className="chat-icon">
                {mess.role === "user" ? (
                  <GoPersonFill />
                ) : (
                  <BiSolidHappyBeaming
                    color={
                      account.IconColor !== undefined
                        ? account.IconColor
                        : "#D6133B"
                    }
                  />
                )}
              </div>
              <div className="">
                <div
                  className={
                    mess.role === "user"
                      ? "chat-user-message-wrap"
                      : "chat-gemini-message-wrap"
                  }
                >
                  <p
                    className="no chat-message"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    <Typewriter text={mess.message} />
                  </p>
                </div>
                <p className="no chat-date">{mess.date}</p>
              </div>
            </div>
          ))}

        {showComplaintForm && (
          <div className="form">
            <p className="no form-header">Submit a complaint</p>
            <br />
            <div className="small-gap">
              <input
                type="text"
                id="tbFullName"
                className="form-textfield"
                placeholder="Full Name"
              />
              <input
                type="text"
                id="tbEmail"
                className="form-textfield"
                placeholder="Email"
              />
              <textarea
                id="taMessage"
                placeholder="Enter text here.."
                className="no form-textarea jakarta"
              ></textarea>
              <div className="form-split">
                {image && (
                  <div>
                    <img
                      src={URL.createObjectURL(image)}
                      alt="Selected"
                      style={{ maxWidth: "100%", maxHeight: "300px" }}
                    />
                  </div>
                )}
                <input
                  type="file"
                  id="fpImage"
                  className="form-filepicker"
                  accept=".png,.jpg,.jpeg,.heic,.heif"
                  onChange={onPickImage}
                />
              </div>
              <div className="form-split">
                <button
                  className="form-btn"
                  onClick={() => {
                    setShowComplaintForm(false);
                  }}
                >
                  Cancel
                </button>
                <button className="form-btn submit" onClick={onSubmitComplaint}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}
        {showContactForm && (
          <div className="form">
            <p className="no form-header">Contact Form</p>
            <br />
            <div className="small-gap">
              <input
                type="text"
                id="tbFullName"
                className="form-textfield"
                placeholder="Full Name"
              />
              <input
                type="text"
                id="tbEmail"
                className="form-textfield"
                placeholder="Email"
              />
              <textarea
                id="taMessage"
                placeholder="Enter text here.."
                className="no form-textarea jakarta"
              ></textarea>
              <div className="form-split">
                <button
                  className="form-btn"
                  onClick={() => {
                    setShowContactForm(false);
                  }}
                >
                  Cancel
                </button>
                <button className="form-btn submit" onClick={onSubmitContact}>
                  Submit
                </button>
              </div>
              <button
                className="form-btn"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "12px",
                  fontSize: "20px",
                  padding: "16px 20px",
                  width: "fit-content",
                  marginLeft: "auto",
                  marginTop: "10px",
                }}
                onClick={() => {
                  callNumber(account.Phone);
                }}
              >
                <p className="no">Call</p>
                <BiPhoneCall size={20} />
              </button>
            </div>
          </div>
        )}
        {toggleChatMenu && (
          <div className="chat-menu-wrap">
            {squareMenu
              .map((ting) => {
                return ting.category;
              })
              .map((cat, c) => {
                return (
                  <p className="no" key={c}>
                    {cat}
                  </p>
                );
              })}
          </div>
        )}
        <p className="small">Coco might get some things wrong.</p>
      </div>
      <div className="actions-wrap">
        <div>
          {showBagItems && (
            <div className="bag-items-wrap">
              <p className="no bag-items-title">Your Bag ({bagItems.length})</p>
              {bagItems.length > 0 && (
                <div>
                  <br />
                  <div className="bag-items">
                    {bagItems.map((item, i) => {
                      return (
                        <div className="" key={i}>
                          <div className="separate_h">
                            <div>
                              <p className="no bag-item-name">{item.name}</p>
                              <p className="no bag-item-total">
                                ${item.total.toFixed(2)}
                              </p>
                              <p className="no bag-item-note">{item.note}</p>
                              {item.itemOptions !== null &&
                                item.itemOptions !== undefined && (
                                  <div className="bag-item-option">
                                    {item.itemOptions.map((opt, o) => {
                                      return (
                                        <p className="no bag-item-note" key={o}>
                                          {opt.name}
                                        </p>
                                      );
                                    })}
                                  </div>
                                )}
                              {item.modifiers !== null &&
                                item.modifiers !== undefined && (
                                  <div>
                                    {item.modifiers.map((mod, m) => {
                                      return (
                                        <div
                                          className="bag-item-option"
                                          key={m}
                                        >
                                          <p className="no bag-item-note">
                                            {mod.name}
                                          </p>
                                          <p className="no bag-item-note">
                                            {mod.quantity}x
                                          </p>
                                          <p className="no bag-item-note">
                                            ${mod.price.toFixed(2)}
                                          </p>
                                        </div>
                                      );
                                    })}
                                  </div>
                                )}
                            </div>
                            <p className="no bag-item-quantity">
                              {item.quantity} x
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          )}
          {showMenu && (
            <div className="bag-items-wrap">
              <p className="no bag-items-title">Menu</p>
              <br />
              {showLoadingMenu && (
                <p style={{ fontSize: "16px", fontWeight: 400 }}>
                  Loading menu...
                </p>
              )}
              <div className="menu-wrap">
                {removeDuplicates(
                  squareMenu.map((ting) => {
                    return ting.category !== "" ? ting.category : "Misc";
                  })
                ).map((category, c) => {
                  return (
                    <div className="" key={c}>
                      <p className="no bag-item-cat">{category}</p>
                      {squareMenu
                        .filter((ting) => ting.category === category)
                        .map((item, i) => {
                          return (
                            <div
                              className="pointer menu-item"
                              key={i}
                              onClick={() => {
                                sendPromptMessage(
                                  `I want to order ${item.itemName}`
                                );
                                setShowMenu(false);
                                // inputRef.current.focus();
                              }}
                            >
                              <div>
                                <div className="separate_h">
                                  <p className="no menu-name">
                                    {item.itemName}
                                  </p>
                                  <BiChevronRight size={30} />
                                </div>
                                {item.variations.map((option, o) => {
                                  return (
                                    <div className="side-by" key={o}>
                                      <p className="no menu-option green">
                                        {option.name}
                                      </p>
                                      <p className="no menu-option green">
                                        ${option.price.toFixed(2)}
                                      </p>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {showFAQ && (
            <div className="bag-items-wrap">
              <p className="no bag-items-title">Frequently Asked Questions</p>
              <br />
              <div className="faqs">
                {faqs.map((faq, i) => {
                  return (
                    <button
                      key={i}
                      className="faq-btn"
                      onClick={() => {
                        sendPromptMessage(faq);
                        setShowFAQ(false);
                        // inputRef.current.focus();
                      }}
                      disabled={!isChatReady}
                    >
                      {faq}
                    </button>
                  );
                })}
              </div>
            </div>
          )}
        </div>

        <div className={`separate_h`}>
          {/* QUESTIONS */}
          {!showBagItems && (
            <button
              className="action-btn action-bottom-left"
              onClick={() => {
                setShowMenu(false);
                setShowBagItems(false);
                setShowFAQ((prev) => !prev);
              }}
            >
              {showFAQ ? <FaXmark /> : <AiOutlineQuestionCircle />}
            </button>
          )}
          {/* PAY NOW */}
          {url !== "" && (
            <button
              className="pay-now-btn"
              onClick={() => {
                setUrl("");
                window.open(url, "_blank");
              }}
            >
              Pay Now
            </button>
          )}
          {/* BAG */}
          <div>
            {!showBagItems && (
              <button
                className="action-btn action-bottom-top-right"
                onClick={() => {
                  setShowBagItems(false);
                  setShowFAQ(false);
                  setShowMenu((prev) => !prev);
                }}
              >
                {showMenu ? <FaXmark /> : <BiSolidFoodMenu />}
              </button>
            )}
            <button
              className="action-btn action-bottom-right"
              onClick={() => {
                setShowMenu(false);
                setShowFAQ(false);
                setShowBagItems((prev) => !prev);
              }}
            >
              {showBagItems ? <FaXmark /> : <MdShoppingBag />}
            </button>
          </div>
        </div>
      </div>
      <div className="bottom">
        {buttonOptions.length > 0 && (
          <div className="button-opts">
            {buttonOptions.map((btn, i) => {
              return (
                <button className="button-opt" key={i} onClick={btn.action}>
                  {btn.text}
                </button>
              );
            })}
          </div>
        )}
        <div className="bottom-input">
          <input
            type="text"
            id="tbMessage"
            ref={inputRef} // Attach the ref to the input field
            className={`bagel-input jakarta`}
            placeholder={
              isChatReady ? "Enter text here..." : "Initializing chat..."
            }
            disabled={!isChatReady}
          />
          <button onClick={sendMessage} disabled={!isChatReady}>
            <FaCircleArrowUp className="bottom-input-icon" />
          </button>
          <button
            onClick={() => {
              setToggleVoice(true);
            }}
          >
            <FaMicrophone className="bottom-input-icon" />
          </button>
        </div>
      </div>

      {/* VOICE MODE */}
      {toggleVoice && (
        <div className="voice-wrap">
          <div className="voice-back">
            <button
              onClick={() => {
                setToggleVoice(false);
              }}
              className="voice-chat"
            >
              <BsArrowRightCircleFill className="voice-chat-icon" />
            </button>
          </div>
          <div className="voice-top">
            <p className="no text-center">Tap on Coco to hear her response.</p>
          </div>
          <div className="voice-mid">
            <div
              className="voice-coco pointer"
              onClick={() => {
                function_textToSpeech(
                  messages[messages.length - 1].message,
                  (speech) => {
                    const audio = new Audio(speech);
                    audio.play();
                  }
                );
              }}
            >
              <img
                src={thinking ? thinkingGif : cocoGif}
                className="voice-coco-img"
              />
            </div>
            <div className="voice-message-wrap">
              <p
                className={`no voice-message ${
                  messages[messages.length - 1].role === "gemini"
                    ? "role-gemini"
                    : "role-user"
                }`}
              >
                {messages[messages.length - 1].message}
              </p>
            </div>
            <div
              className="voice-mic pointer"
              onClick={() => {
                handleRecordClick();
              }}
            >
              {isRecording ? (
                <FaStop className="voice-mic-icon" />
              ) : (
                <FaMicrophone className="voice-mic-icon" />
              )}
            </div>
            {voiceRecording !== null && <audio controls={true} src={URL.createObjectURL(voiceRecording)} />}

          </div>
          <div className="separate_h voice-bottom"></div>
        </div>
      )}
    </div>
  );
}

export default App;

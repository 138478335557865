import { initializeApp } from "firebase/app";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { randomString } from "./functions";
import {
  RecaptchaVerifier,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
  signOut,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyC1zgdg49ulBpO5QI5q4UpEf0d-tuaWJT4",
  authDomain: "chat-with-coco-3d9b0.firebaseapp.com",
  projectId: "chat-with-coco-3d9b0",
  storageBucket: "chat-with-coco-3d9b0.appspot.com",
  messagingSenderId: "687123759843",
  appId: "1:687123759843:web:1880efb955dcec55d33bd6",
  measurementId: "G-PDNRBVQCPN",
};

//
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage();
const auth = getAuth();
auth.languageCode = "it";

// FIREBASE
export async function firebase_GetAccount(businessName, setter) {
  try {
    // Create a query against the collection
    const q = query(
      collection(db, "Accounts"),
      where("BusinessName", "==", businessName)
    );

    // Execute the query
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      // Assuming you want to handle only the first matched document
      const accountData = querySnapshot.docs[0].data();
      setter(accountData);
    } else {
      console.log("No matching documents.");
      setter(null); // or handle the case where no documents match
    }
  } catch (error) {
    console.error("Error getting document: ", error);
    setter(null); // or handle the error appropriately
  }
}
export async function storage_UploadImage(image, setter) {
  try {
    const storageRef = ref(storage, `Images/${randomString(14)}.jpg`);

    // Upload the image
    const snapshot = await uploadBytes(storageRef, image);
    console.log("Uploaded a blob or file!", snapshot);

    // Get the download URL
    const url = await getDownloadURL(snapshot.ref);
    console.log("File available at", url);

    setter(url);
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error; // Re-throw the error to be handled by the calling function
  }
}
export async function firebase_GetDocument(collection, documentId, setter) {
  const docRef = doc(db, collection, documentId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data());
    const obj = {
      id: docSnap.id,
      ...docSnap.data(),
    };
    setter(obj);
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
}
export async function auth_SignIn(email, password, setter) {
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      console.log(user);
      // GET THIS USER
      firebase_GetDocument("Accounts", user.uid, (thisUser) => {
        setter(thisUser);
      });
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
    });
}
export async function auth_CheckSignIn(isSignedIn = true, setter) {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      const uid = user.uid;
      firebase_GetDocument("Accounts", uid, (thisUser) => {
        setter(thisUser);
      });
      // ...
    } else {
      isSignedIn(false);
    }
  });
}
export async function firebase_UpdateDocument(
  collection,
  documentId,
  args,
  success
) {
  const washingtonRef = doc(db, collection, documentId);
  // Set the "capital" field of the city 'DC'
  await updateDoc(washingtonRef, args)
    .then(() => {
      success(true);
    })
    .catch((error) => {
      success(false);
    });
}
export async function auth_SignOut(success) {
  signOut(auth)
    .then(() => {
      // Sign-out successful.
      success(true)
    })
    .catch((error) => {
      // An error happened.
      success(false)
    });
}

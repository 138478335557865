export const serverURL = "https://buttered-candied-pipe.glitch.me";

// SQUARE API
export async function square_GetMenu(account, setter) {
  const url = `${serverURL}/square-get-menu`;

  const body = {
    account: account,
  };
  console.log(body);
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    const data = await response.json();
    const items = data.menu.filter((ting) => ting.type === "ITEM");
    const newData = [];

    for (let i = 0; i < items.length; i += 1) {
      const item = items[i];

      const name = item.itemData.name;
      const locationIds =
        item.presentAtLocationIds !== null &&
        item.presentAtLocationIds !== undefined
          ? item.presentAtLocationIds
          : [];
      let category = "";
      if (item.itemData.categoriesList !== undefined) {
        category = item.itemData.categoriesList[0]?.categoryData.name || "";
      }

      // VARIATIONS
      let variations = [];
      if (item.itemData.variations !== undefined) {
        variations = item.itemData.variations.map((ting) => {
          const price =
            ting.itemVariationData.priceMoney?.amount !== undefined
              ? ting.itemVariationData.priceMoney.amount * 0.01
              : 0;

          return {
            id: ting.id,
            name: ting.itemVariationData.name,
            price: price,
          };
        });
      }

      const images =
        item.itemData.imageUrls !== undefined ? item.itemData.imageUrls : [];

      // ITEM OPTIONS
      let itemOptions = [];
      if (item.itemData.itemOptionsData !== undefined) {
        itemOptions = item.itemData.itemOptionsData.map((ting) => {
          return {
            id: ting.id,
            category_name: ting.itemOptionData.name,
            options: ting.itemOptionData.values.map((ting2) => {
              return {
                id: ting2.id,
                item_option_name: ting2.itemOptionValueData.name,
              };
            }),
          };
        });
      }

      const description = item.itemData.descriptionPlaintext;

      // MODIFIERS
      let modifiers = [];
      if (item.itemData.modifierLists !== undefined) {
        modifiers = item.itemData.modifierLists.map((ting) => {
          return {
            category_name: ting.modifierListData.name,
            options: ting.modifierListData.modifiers.map((ting2) => {
              const price =
                ting2.modifierData.priceMoney?.amount !== undefined
                  ? ting2.modifierData.priceMoney.amount * 0.01
                  : 0;

              return {
                id: ting2.id,
                modifier_name: ting2.modifierData.name,
                price: price,
              };
            }),
          };
        });
      }

      const detailedItem = {
        itemName: name,
        category: category,
        variations: variations,
        images: images,
        options: itemOptions,
        description: description,
        modifiers: modifiers,
        locationIds: locationIds,
      };

      newData.push(detailedItem);
    }

    setter(newData);
  } catch (error) {
    console.error("Error fetching menu items:", error);
  }
}
export async function square_CreateOrder(
  apiKey,
  locationId,
  lineItems,
  setter
) {
  const body = {
    apiKey: apiKey,
    locationId: locationId,
    lineItems: lineItems.map((item) => ({
      name: item.name,
      quantity: item.quantity.toString(),
      catalogObjectId: item.catalogObjectId, // Use the correct field for catalogObjectId
      basePriceMoney: item.basePriceMoney,
      modifiers:
        item.modifiers !== null && item.modifiers !== undefined
          ? item.modifiers.map((modifier) => ({
              catalogObjectId: modifier.catalogObjectId, // Use the correct field for catalogObjectId
              name: modifier.name,
              quantity: modifier.quantity.toString(),
              basePriceMoney: modifier.basePriceMoney,
            }))
          : [],
      note: item.special_instructions,
    })),
  };

  console.log("Request body:", body); // Log the body to see what is being sent

  try {
    const response = await fetch(`${serverURL}/square-create-order`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    setter({
      orderId: data.orderId,
      paymentLink: data.paymentLink,
    });
  } catch (error) {
    console.error("Error creating order:", error);
    throw new Error("Error creating order");
  }
}
